import { Api } from "../../Config/Request";
export const requestchat = async (data) => {
  return Api.PostRequest( 
    // "https://demo-bot-functions-app.azurewebsites.net/api/queryAssistantProxy",
    // "https://prod-30.northcentralus.logic.azure.com:443/workflows/b9c7d1e9b6bf48568c7ae8a0ffabf705/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=KJru6ZAt7FCMJqS7w5gfRlOVIkv4YNAuf8160pYs-9E",
    "https://worx-assistant-function-app.azurewebsites.net/api/queryAssistantProxy",
    data,
    true
  );
};
export const requestasynmsg = async (data) => {
  return Api.PostRequest( 
  "https://worx-assistant-async-function-app.azurewebsites.net/api/queryAssistantProxyStream",
  data,
  true
);
};
export const requestlogo = async (data) => {
  return Api.GetRequest(`identify?clientKey=${data.key}`);
};
const ApiRequests = {
  requestchat,
  requestlogo,
  requestasynmsg
};
export default ApiRequests;
