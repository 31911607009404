import { Box, Grid, Button, Container, TextField, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Schema } from "../Config/Validator";
import { getLogin } from "../Redux/Private/PrivateAction";
import Toastify from "../Components/SnackBar/Toastify";

const defaultValues = {
  username: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = (event) => {
    event.preventDefault();
    setIsFocused(false);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const newState = useSelector((state) => state?.LoginData);
  useEffect(() => { }, [newState]);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(Schema.LOGIN),
  });
  const { errors } = formState;

  const onSubmit = (model) => {
    let item = model;
    dispatch(getLogin(item, navigate));
  };

  const Style = {
    input: {
      border: "none",
      bordeRadius: "50px",
        "& .MuiInputBase-root": {
        background: isFocused
          ? "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box"
          : "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
        border: isFocused ? "none" : "2px solid",
        bordeRadius: "50px",
        borderColor: " #02010100",
      },
      "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill":{
        p:{xs:"10px 10px"}
      },
      "& .MuiFormHelperText-root": {
        // Style the root element of MuiFormHelperText
        margin: 0,
        marginTop: 0,
        color: "red",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          bordeRadius: "50px",
          borderColor: "transparent", // Removes the border
        },
        "&:hover fieldset": {
          borderColor: "transparent", // Removes the border on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent", // Removes the border when focused
        },
      },
    },
    logo: {
      color: "#343568",
      fontSize: "24px",
      fontWeight: 700,
    },
    logo1: {
      color: "#343568",
      fontSize: "20px",
      fontWeight: 700,
    },
    btn: {
      bgcolor: "#343568",
      color: "white",
      fontSize:{xs:"12px",md:"16px"},
      width: {xs:"20%",md:"25%"},
      "&.MuiButton-root": {
        bgcolor: "#343568",
        color: "white",
        fontSize:{xs:"12px",md:"16px"},
        width: { xs: "35%", sm: "25%" },
      },
    },
  };
  return (
    <>
      <Box sx={style.outBox} className="form-screens">
        <Container maxWidth="xl" sx={{
          "&.MuiContainer-root": {
            pl: 0
          }
        }}>
          <Box sx={style.mainBox}>
            <Box
              sx={{
                width: "50%",
                height: "calc(100vh - 100px)",
                p: "35px 40px 40px 0px",
                display: { xs: "none", md: "flex" },
                background: "#F6F6FE",
                alignItems: "center",
              }}>
              <img
                alt="logo"
                style={{
                  width: "100%",
                  height: "80%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                className="img"
                src={require("../assests/The-Standard-Passage1 1.png")}
              />
            </Box>
            <Box sx={style.leftBox}>
              <Box
                sx={{
                  width: "80%",
                  pb: 5,
                  display: "flex",
                }}>
                <img src={require("../assests/w-logo 1@2x.png")} width={"89px"} height={"61px"} alt="w-logo"/>
                <Box
                  sx={{
                    ml: 2,
                  }}>
                  <Typography sx={Style.logo}>WORX</Typography>
                  <Typography sx={Style.logo1}>AI Assistant</Typography>
                </Box>
              </Box>
              <form
                name="LoginForm"
                className="loginForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "80%",
                  width: "80%",
                  // justifyContent: 'space-evenly',
                }}>
                <Grid>
                  <FormLabel sx={{ color: "#333",fontSize:{xs:"14px",md:"16px"} }}>Nome utente<span style={style.span}>*</span></FormLabel>
                  <Controller
                    name={"username"}
                    control={control}
                    sx={{
                      mt: 10,
                      pb: 0,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        helperText={errors?.username?.message}
                        // errors={!!errors.username}
                        // onChange={(e) => setMessage(e.target.value)}
                        // value={message}
                        // onFocus={handleFocus}
                        // onBlur={handleBlur}
                        // onKeyDown={handleKeyDown} // Attach keydown event listener
                        name="username"
                        sx={Style.input}
                      />
                    )}
                  />
                </Grid>
                <Box sx={{ height: "15px" }} />

                <Grid>
                  <FormLabel sx={{ color: "#333",fontSize:{xs:"14px",md:"16px"}  }}>Password<span style={style.span}>*</span></FormLabel>
                  <Controller
                    name={"password"}
                    control={control}
                    sx={{
                      pb: 0,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        type="password"
                        name="password"
                        sx={Style.input}
                        helperText={errors?.password?.message}
                        // errors={!!errors.password}
                      />
                    )}
                  />
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { md: "flex-start" },
                    mt: 4,
                    mb: 2,
                    pr: { xs: 1, md: 10 },
                    // pl: { xs: 0, sm: 1 },
                  }}>
                  <Button type="submit" sx={Style.btn}>
                    Log in
                  </Button>
                  <Toastify />
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Login;

const style = {
  outBox: {
    width: "100%",
    //bgcolor: '#F3F0EE',
    overflow: "hidden",
    // pt: 5,
    // pb: 5,
  },
  mainBox: {
    // maxWidth: '1530px',
    width: "100%",
    minHeight: "500px",
    ml: "auto",
    mr: "auto",
    // p: '40px',
    bgcolor: "white",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: { xs: "center", md: "flex-end" },
    alignItems: "center",
  },
  leftBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: { xs: "100%", md: "50%" },
  },
  button: {
    width: "30%",
    "&.MuiButtonBase-root:hover": {
      bgcolor: "#CB2128",
      border: "1.5px solid #FF8D2A",
    },
    bgcolor: "#CB2128",
    color: "#FFFFFF",
    border: "1.5px solid #FF8D2A",
    mb: 2,
    mt: 4,
    borderRadius: "5px",
    fontSize: { xs: "16px", md: "16px", lg: "16px" },
    fontWeight: 400,
    textTransform: "none",
    transform: "skewX(-20deg)",
    "& .MuiButton-label": {
      transform: "skewX(20deg)",
    },
  },
  span: {
    color: "#ff0000"
  }
};
