import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { chat } from "../Config/MockData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Config/fontAwesomeConfig";
import { useDispatch, useSelector } from "react-redux";
import { getLoadingStatus, getLogo, sendasyncmessage, sendmessage } from "../Redux/Public/chataction";
import { storage } from "../Config/Storage";
import TypingText from "../Config/Typingtext";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom"
import axios from "axios";

const Chatarea = () => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchParams] = useSearchParams()
  const chatEndRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints?.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints?.down("lg"));
  const isLargeDevice = useMediaQuery(theme.breakpoints?.down("lg"));
  const { list, loading, details, response } = useSelector((state) => state?.ChatData) || {};
  const [message, setMessage] = useState("");
  const [chatmess, setChatmess] = useState(chat);
  const [recentList, setRecentList] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [stream_upload, setstream_upload] = useState(false);
  const [checkTypingStatus, setCheckTypingStatus] = useState(false);
  const [open, setOpen] = React.useState(isMobile || isIpad ? false : true);
  const [img, setImg] = useState("")
  const textBoxRefs = useRef([]);
  const [asynReply, setasynReply] = useState('');
  const [messUpdated, setmessUpdated] = useState(false);
  const [consistencyOutput, setconsistencyOutput] = useState('');
  const [requestContextOutput, setrequestContextOutput] = useState('');
  const handleFocus = (event) => {
    event.preventDefault();
    setIsFocused(false);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    dispatch(getLogo({ key: searchParams.get("id") }));
    dispatch(getLoadingStatus(true));
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }

    // Attach the event listener to the window object
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Re-render page on list change
  useEffect(() => {
    textBoxRefs.current.forEach((ref) => {
      if (ref && ref.scrollHeight > ref.clientHeight) {
        ref.classList.add("overflow");
      }
    });
    setCheckTypingStatus(false)
    if (list) {
      setChatmess([...chatmess, { image: require("../assests/w-logo 1@2x.png"), mess: "", reply: list.reply }]);
      setCheckTypingStatus(true)
    }
    if (details) {
      let mess = details?.welcome_message?.replace(/\\n/g, '\n')
      setChatmess([...chatmess, { image: require("../assests/w-logo 1@2x.png"), mess: "", reply: mess }]);
      setImg(details?.logo_url)
      setstream_upload(details.stream_enabled)
    }


  }, [list, details]);

  useEffect(() => {

    if (messUpdated) {
      let mess = messUpdated?.replace(/\\n/g, '\n')
      // mess = mess.replace(/\\u00f2"/g, '"');
      // mess = mess.replace(/\\u00e0"/g, '"');
      // mess = mess.replace(/\\u"/g, '"');
      // mess = mess.replace(/\\e"/g, '"');
      // mess = mess.replace(/\[0-9]"/g, '"');
      // mess = mess.replace(/\\f"/g, '"');
      setChatmess(prevChatmess => {
        let updatedChatmess = []
        // Create a copy of the current chatmess array
        // console.log(prevChatmess);

        updatedChatmess = [...prevChatmess];
        // console.log(updatedChatmess);

        // Check if there are any items in the chatmess array
        if (updatedChatmess.length > 0 && updatedChatmess[updatedChatmess.length - 1].reply !== "") {
          // Update the last item's reply
          updatedChatmess[updatedChatmess.length - 1] = {
            image: require("../assests/w-logo 1@2x.png"),
            mess: "",
            reply: mess
          };
        } else {
          // If chatmess is empty, add a new entry
          updatedChatmess.push({
            image: require("../assests/w-logo 1@2x.png"),
            mess: "",
            reply: mess
          });
          // setChatmess([...chatmess, { image: require("../assests/w-logo 1@2x.png"), mess: "", reply: mess }]);
        }

        // Return the updated array to update the state
        return updatedChatmess;
      });

      // Clear messUpdated to avoid continuous updates
      setmessUpdated(null);
    }

  }, [messUpdated]);

  //async response function
  const processAndStoreStream = async (data) => {
    Object.assign(data, { authToken: storage.fetch.chatToken() });

    try {
      const response = await fetch('https://worx-assistant-async-function-app.azurewebsites.net/api/queryAssistantProxyStream', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });

      if (response && response.body && typeof response.body.getReader === 'function') {
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let done = false;
        let accumulatedReply = "";
        let completeReply = "";
        let accumulatedReply1 = "";

        let stopUpdating = false;  // Flag to control whether to call setmessUpdated

        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;

          if (value) {
            const chunk = decoder.decode(value, { stream: true });

            // Extract `reply` values from the chunk
            const matches = chunk.match(/"reply":\s*"([^"]*)"/g);
            if (matches) {
              for (const match of matches) {
                // Extract the reply value
                let replyValue = match.replace(/"reply":\s*"([^"]*)"/, '$1');
                accumulatedReply += replyValue;

                if (!stopUpdating ) {
                  if( replyValue !== "<<"&& replyValue !== "<!"&& replyValue !== "<" &&replyValue !== "<<!"){
                  accumulatedReply1 += replyValue;
                  setmessUpdated(accumulatedReply1); // Call setmessUpdated only if not stopped
                  console.log(accumulatedReply1);
                  
    dispatch(getLoadingStatus(false));
                  
                  }else {
                    // Set the flag to stop updating setmessUpdated
                    stopUpdating = true;
  }
                }

                // Process special delimiters and extract JSON if present
                let specialDelimiterStart1 = accumulatedReply.indexOf("<<!");
                let specialDelimiterStart2 = accumulatedReply.indexOf("<!");
                let specialDelimiterEnd = accumulatedReply.indexOf("!>>");
                let specialDelimiterStart;

                if (specialDelimiterStart1 !== -1) {
                  specialDelimiterStart = specialDelimiterStart1;
                } else if (specialDelimiterStart2 !== -1) {
                  specialDelimiterStart = specialDelimiterStart2;
                } else {
                  specialDelimiterStart = -1;
                }

                if (specialDelimiterStart !== -1 && specialDelimiterEnd !== -1) {
                  // Extract the JSON part containing the outputs
                  // console.log(specialDelimiterStart, specialDelimiterEnd);

                  const outputJsonString = chunk.substring(specialDelimiterStart + 3, specialDelimiterEnd);
                  // console.log(accumulatedReply.slice(specialDelimiterStart + 3, specialDelimiterEnd));
                    const outputs = `${accumulatedReply.slice(specialDelimiterStart + 3, specialDelimiterEnd)}}`;
                    const jsonString = outputs
                    .replace(/\\(\w+?)\\\s*:/g, '"$1":')  // Add double quotes around keys
                    .replace(/:\s*\\([^,}\]]+)\\\s*/g, ': "$1"')
                    .replace(/\\\"/g, '"');  
                    let replacedFirst = jsonString.replace(/\\/, '",');

                    // Replace the second backslash with a double quote
                    let replacedSecond = replacedFirst.replace(/\\/, '"');
                    // console.log(replacedSecond);
                  
                    // Parse the string into JSON
                      const jsonObject = JSON.parse(replacedSecond);
                      // console.log(jsonObject);
                    
                    setconsistencyOutput(jsonObject?.consistency_output);
                    setrequestContextOutput(jsonObject?.request_context_output);
                  
                }
              }
            } else {
              // This is normal chat content; append to the complete reply
              completeReply += chunk.replace(/<<!.*!>>/, '');
              completeReply = `${completeReply}}`
                .replace(/\\(\w+?)\\\s*:/g, '"$1":')  // Add double quotes around keys
                .replace(/:\s*\\([^,}\]]+)\\\s*/g, ': "$1"')  // Add double quotes around values and fix escapes
                .replace(/\\\"/g, '"');
            }
          }
        }

        let mess = accumulatedReply.replace(/\\n/g, '\n');
        // Handle final accumulated reply if needed

      } else {
        console.error('Response body is not a readable stream or getReader is not a function');
      }
    } catch (error) {
      console.error('Error fetching or processing stream:', error);
    }
  };



  // Re-render page on chatmess change
  useEffect(() => {
    if (chatEndRef.current) {
      const scrollHeight = chatEndRef.current.scrollHeight;
      const clientHeight = chatEndRef.current.clientHeight;
      const scrollToHeight = scrollHeight - clientHeight;
      chatEndRef.current.scrollTo(0, scrollToHeight);
    }
    storage.set.chat(chatmess);

  }, [chatmess]);

  //Send message function
  const handleSendMessage = async () => {
    if (checkTypingStatus) {
      let data = {
        query: `${message}`,
        chat_history: list?.chat_history ? list?.chat_history : [{
          "inputs": {
            "fetched_docs_ids": [],
            "query": `${consistencyOutput}`
          },
          "outputs": {
            "consistency_output": `${consistencyOutput}`,
            "reply": `${asynReply}`,
            "request_context_output": `${requestContextOutput}`
          }
        }],
        fetched_docs_ids: list?.fetched_docs_ids ? list?.fetched_docs_ids : []
      };
      setCheckTypingStatus(false)
      setRecentList((prevRecentList) => {
        // Update all items in recentList to set clicked to false
        return prevRecentList.map((item) => {
          return { ...item, clicked: false };
        });
      });
      if (message.trim() !== "") {
        setChatmess([...chatmess, { image: require("../assests/user.png"), mess: message, reply: "" }]);
        // Update recentList after the state has been updated
        setRecentList((prevRecentList) => {
          // Create a new array with the new message appended to the beginning
          const newRecentList = [
            { image: require("../assests/user.png"), text: message, clicked: true, index: chatmess.length },
            ...prevRecentList,
          ];
          return newRecentList;
        });

        setMessage(""); // Clear the TextField after sending the message
      }


      // Dispatch sendmessage action
      if (!stream_upload) {
        dispatch(sendmessage(data));
      } else {
        processAndStoreStream(data);
        dispatch(getLoadingStatus(true));
      }
    }

  };

  //Handle send message on enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      if (message.length !== 0) handleSendMessage();
    }
  };
  //Handle clear chat history
  const handleDelete = () => {
    setChatmess([]);
    dispatch(getLogo({ key: "EXT_CLIENT_000020" }));
    setRecentList([]);
    if (isMobile) {
      handleDrawerClose()
    }
  };

  //Handle Scroll on select from history
  const handleClick = (index, i) => {
    const targetElement = textBoxRefs.current[index];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    setRecentList((prevRecentList) =>
      prevRecentList.map((item, ind) => ({
        ...item,
        clicked: ind === i,
      }))

    );
    if (isMobile) {
      handleDrawerClose()
    }

  };

  //Style CSS
  const Style = {
    grad: {
      background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
      border: "2px solid",
      borderRadius: "10px",
      borderColor: " #02010100",
      width: "10%",
      minWidth: "950px",
      ml: 2,
      maxWidth: "100%", // Default max width
      height: "fit-content",
    },
    grad1: {
      background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
      border: "2px solid",
      borderRadius: "10px",
      borderColor: " #02010100",
      width: "100%",
      ml: 2,
      maxWidth: "100%", // Default max width
      height: "fit-content",
      wordWrap: "word-break",
      pr: 2,
    },
    overflow: {
      maxWidth: "90%", // Width when overflow occurs
    },
    logo: {
      color: "#343568",
      fontSize: { xs: "16px", md: "20px" },
      fontWeight: 700,
    },
    logo1: {
      color: "#343568",
      fontSize: { xs: "14px", md: "18px" },
      fontWeight: 600,
    },
    button: {
      borderRadius: "50%",
      bgcolor: message.length === 0 ? "#C8C8C8" : "#343568",
      height: { xs: "30px", md: "40px" },
      width: { xs: "30px", md: "40px" },
      "&.MuiButton-root": { minWidth: "30px" },
      "&.MuiButton-root:hover": {
        borderRadius: "50%",
        bgcolor: "#343568",
      },
    },
    input: {
      border: "none",
      bordeRadius: "50px",
      "& .MuiInputBase-root": {
        background: isFocused
          ? "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box"
          : "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
        border: isFocused ? "none" : "2px solid",
        bordeRadius: "50px",
        borderColor: " #02010100",
        p: { xs: "10px 10px", md: "15px 15px" }

      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          bordeRadius: "50px",
          borderColor: "transparent", // Removes the border
          p: { xs: "10px 10px", md: "auto" }
        },
        "&:hover fieldset": {
          borderColor: "transparent", // Removes the border on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent", // Removes the border when focused
        },
      },
    },
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  return (
    <Grid container>
      <CssBaseline />
      <AppBar
        sx={{
          bgcolor: "#ffffff",
          display: "flex",
          boxShadow: "none",
          borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
          pt: 1.5,
          pb: 1.5,
          "&.MuiAppBar-root": {
            position: "unset",
          },
        }}>
        <Toolbar sx={{
          display: "flex",
          justifyContent: "end",
          "&.MuiToolbar-root": {
            minHeight: "16px"
          }
        }}>
          <Box
            sx={{
              border: 1,
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%",
            }}>
            {!open && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  color: "#B2C1F0",
                  display: { xs: "flex", lg: "none" },
                  width: "50px",
                  height: "50px",
                  marginRight: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <MenuIcon sx={{ fontSize: { xs: "30px", md: "35px" } }} />
              </IconButton>
            )}
            {img && <img
              style={{
                height: "60px",
                width: "100px",
                // borderRadius: "60px",
                margin: "26px",
              }}
              src={img}
              alt=""
            />}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        onClose={() => setOpen(false)}
        sx={{
          flexShrink: 0,
          zIndex: 9999,
          "& .MuiDrawer-paper": {
            width: "350px",
            boxSizing: "border-box",
            maxHeight: "100%",
          },
          ml: "30px",
          mr: "30px",
        }}
        // ref={divRef2}
        id="drawer"
        variant={isLargeDevice ? "temporary" : "persistent"}
        anchor="left"
        open={open}>
        <Grid>
          <Box sx={{ m: 2, display: "flex", maxHeight: "200px", position: "relative", mt: '20px' }}>
            <img width={isMobile ? "70px" : "89px"} height={isMobile ? "51px" : "61px"} src={require("../assests/w-logo 1@2x.png")} alt="w-logo" />
            <Box
              sx={{
                ml: 2,
                mt: 1,
                mb: { xs: 1, md: 3 },
              }}>
              <Typography sx={Style.logo}>WORX</Typography>
              <Typography sx={Style.logo1}>AI Assistant</Typography>
            </Box>
            {open && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerClose}
                edge="start"
                sx={{
                  color: "#B2C1F0",
                  display: { xs: "flex", lg: "none" },
                  ml: "5px",
                  position: "absolute",
                  right: "0px",
                }}>
                <CloseIcon sx={{ fontSize: { xs: "25px", md: "35px" } }} />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              backgroundImage: "linear-gradient(to right,#E9EBFF, #F6F2FF,#EEFDFF)",
              // minHeight: { md: "60svh", lg: "65svh", xl: "79svh" },
              height: { xs: "calc(100vh - 115px)", md: "calc(100vh - 210px)" },
              width: "90%",
              borderRadius: "10px",
              ml: 2,
              // p: "10px",
            }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", p: "10px" }}>
              <Typography sx={{ textTransform: "uppercase", color: "#343568", fontSize: { xs: "16px", md: "20px" }, fontWeight: 700 }}>Cronologia</Typography>
              <IconButton
                sx={{
                  border: "1px solid #FF6151",
                  "&.MuiIconButton-root": {
                    p: { xs: "3px", md: "5px" },
                  },
                }}
                disabled={recentList.length === 0 ? true : false}
                onClick={handleDelete}>
                <DeleteIcon sx={{ color: "#FF6151", fontSize: { xs: "14px", md: "18px" } }} />
              </IconButton>
            </Box>
            <Box
              className="chatbox"
              sx={{
                maxHeight: "450px",
                overflow: "scroll",
                mt: 2,
              }}>
              {recentList.length === 0 ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                  <Typography sx={{ fontSize: { xs: "16px", md: "18px" } }}>Nessun dato</Typography>
                </Box>
              ) : (
                recentList.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      ml: 2,
                      position: "relative",
                      mt: 1,
                      width: "100%",
                      boxShadow: "1px solid gray",
                      pr: 0
                    }}
                    style={{ marginLeft: 0, marginTop: "5px" }}>
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        margin: "0px 10px 5px 10px",
                        boxShadow: "1px 3px 3px 0px #00000026",
                        bgcolor: "white",
                        // // border: "1px solid #EBEEFF",
                        // borderRadius: "8px",
                        background: item.clicked
                          ? "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box"
                          : "linear-gradient(#fff, #fff) padding-box, linear-gradient(#EBEEFF, #EBEEFF) border-box",
                        border: "2px solid",
                        bordeRadius: "10px",
                        borderColor: " #02010100",
                        p: 1,
                        width: "100%",
                        textTransform: "none",

                        "&.MuiButton-root:hover": {
                          background:
                            "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
                          border: "2px solid",
                          bordeRadius: "10px",
                          borderColor: " #02010100",
                        },
                        "&.MuiButton-root:active": {
                          background:
                            "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
                          border: "2px solid",
                          bordeRadius: "10px",
                          borderColor: " #02010100",
                        },
                      }}
                      onClick={() => handleClick(item.index, index)}>
                      <img src={require("../assests/message.png")} alt="messageImg" />
                      <Typography sx={{ ml: 1, textAlign: "left", overflow: "hidden", maxWidth: "500px", textOverflow: "ellipsis", color: "#000000", fontSize: { xs: "16px", md: "14px" } }}>
                        {item.text}
                      </Typography>
                    </Button>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Grid>
      </Drawer>
      {
        searchParams.get("id") === null || searchParams.get("id") === undefined || searchParams.get("id") === "" || details === "Client not found" ?
          <>
            <Box sx={{
              height: "10%",
              width: isMobile || isIpad ? "100%" : "calc(100% - 350px)",
              borderRadius: "10px",
              p: { xs: 0, sm: 2 },
              ml: "auto",
            }}>
              <Box
                sx={{
                  backgroundImage: "linear-gradient(to right,#E9EBFF, #F6F2FF,#EEFDFF)",
                  borderRadius: "20px",
                  // width: "95%",
                  minHeight: "450px",
                  m: { xs: 0, sm: 3, md: 3, lg: 3 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                <Typography sx={{ fontSize: "30px" }} component={"h1"}> Client Id Not Found</Typography>
              </Box>
            </Box>
          </>
          :
          <>
            <Box
              sx={{

                width: isMobile || isIpad ? "100%" : "calc(100% - 350px)",
                borderRadius: "10px",
                p: { xs: 2, sm: 2 },
                ml: "auto",
              }}>
              <Box
                sx={{
                  backgroundImage: "linear-gradient(to right,#E9EBFF, #F6F2FF,#EEFDFF)",
                  borderRadius: "20px",
                  // width: "95%",
                  // minHeight: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  p: 2,
                  m: { xs: 0, sm: 4 },
                }}>
                <Box
                  className="chatbox"
                  sx={{
                    overflow: "scroll",
                    height: { xs: "calc(90vh - 245px)", sm: "calc(100vh - 295px)" },
                    // minHeight: { md: "45svh", lg: "45svh", xl: "70svh" },
                    // maxHeight: "420px",
                    // pb: 3,
                  }}
                  ref={chatEndRef}>
                  <>
                    {/* {!loading && */}
                    {chatmess.map((item, index) => (
                      <Box sx={{ display: "flex", height: "auto", mt: 2 }} key={index}>
                        <Box
                          sx={{
                            bgcolor: "white",
                            borderRadius: "50%",
                            width: item.reply !== "" ? { xs: "40px", md: "50px" } : { xs: "40px", sm: "40px", md: "50px" },
                            height: item.reply !== "" ? { xs: "40px", md: "50px" } : { xs: "40px", md: "50px" },
                            p: item.reply !== "" ? isMobile || isIpad ? 1.2 : 1.4 : 0.6,
                            pt: item.reply !== "" ? { xs: 1.4, md: 1.5 } : 0.6
                          }}>
                          <img
                            style={{ width: item.reply !== "" ? isMobile || isIpad ? "22px" : "30px" : isMobile || isIpad ? "30px" : "40px", height: item.reply !== "" ? isMobile || isIpad ? "20px" : "30px" : isMobile || isIpad ? "30px" : "40px" }}
                            src={item.image}
                            alt="w-logo"
                          />
                        </Box>
                        {item.reply !== "" ? (
                          <Box ref={(el) => (textBoxRefs.current[index] = el)} sx={{ ...Style.grad1, wordBreak: "break-word" }}>
                            <Box sx={{ ml: 1, mt: 1.5, mb: 1.5, fontSize: { xs: "14px", md: "1rem" } }}>
                              <TypingText text={item.reply} index={index} chatEndRef={chatEndRef} setCheckTypingStatus={setCheckTypingStatus} checkTypingStatus={checkTypingStatus} />
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Box ref={(el) => (textBoxRefs.current[index] = el)} id={index} className="scroll-to" sx={{ ...Style.grad1, wordBreak: "break-word" }}>
                              <Typography sx={{ ml: 1, mt: 1.4, mb: 1.4, fontSize: { xs: "14px", md: "1rem" } }}>{item.mess}</Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                      // {/* ))*/} 
                    ))}
                    {loading ||  loading === undefined  && (
                      <Box sx={{ display: "flex", height: "auto", mt: 2 }}>
                        <Box sx={{
                          bgcolor: "white", borderRadius: "50%", width: "50px", height: "50px", p: isMobile || isIpad ? 1.2 : 1.4,
                          pt: { xs: 1.4, md: 1.5 }
                        }}>
                          <img style={{ width: isMobile || isIpad ? "22px" : "30px", height: isMobile || isIpad ? "20px" : "30px" }}
                            src={require("../assests/w-logo 1@2x.png")} alt="W-logo" />
                        </Box>
                        <Box sx={Style.grad1}>
                          <Box sx={{ display: "flex", wordBreak: "break-all", justifyContent: "space-evenly", width: { xs: '50%', md: "20% " } }}>
                          <Typography sx={{  mt: 1.4, mb: 1.4, fontSize: { xs: "14px", md: "1rem" },color:"gray" }}>Sporty sta scrivendo...
                          </Typography>                          </Box>
                        </Box>
                      </Box>
                    )}
                    
                  </>
                </Box>
                <Box>
                  <TextField
                    autoFocus
                    fullWidth
                    multiline
                    maxRows={5}
                    placeholder="Inserisci messaggio..."
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown} // Attach keydown event listener
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button disabled={message.length === 0 ? true : false} sx={Style.button} onClick={handleSendMessage}>
                            <FontAwesomeIcon icon={["far", "paper-plane"]} color="white" style={{ marginRight: "2px" }} />
                          </Button>
                        </InputAdornment>
                      ),
                      // disableUnderline: true, // Remove the default underline
                    }}
                    sx={Style.input}
                  />
                </Box>
              </Box>
            </Box>
          </>
      }
    </Grid>
  );
};

export default Chatarea;
