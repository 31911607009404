import { put, takeEvery } from "redux-saga/effects";
import { GET_AYNC_MESS, GET_CHAT, GET_LOADING, GET_LOGO, SET_AYNC_MESS, SET_CHAT, SET_LOADING, SET_LOGO } from "../ConstAction";
import ApiRequests from "../../Services/Public";
import { storage } from "../../Config/Storage";
// import { storage } from "../../Config/Storage";
function* getChat(data) {
  try {
    let response = yield ApiRequests.requestchat(data.data);
   
    yield put({
      type: SET_CHAT,
      response,
      loading: false
    });
  } catch (err) { }
}
function* getLoading(data) {
  try {
    yield put({
      type: SET_LOADING,
      data: data.data,
    });
  } catch (err) { }
}
function* getLogo(data) {
  try {
    let response = yield ApiRequests.requestlogo(data.data);
    if(response){
      storage.set.chatToken(response.data.authToken)
    }
    yield put({
      type: SET_LOGO,
      response,
    });
  } catch (err) { }
}
function* getasynmsg(data) {
  try {
    let response = yield ApiRequests.requestasynmsg(data.data);

    yield put({
      type: SET_AYNC_MESS,
      response,
    });
  } catch (err) { }
}
function* chatSaga() {
  yield takeEvery(GET_CHAT, getChat);
  yield takeEvery(GET_LOADING, getLoading);
  yield takeEvery(GET_LOGO, getLogo);
  yield takeEvery(GET_AYNC_MESS, getasynmsg);
}
export default chatSaga;
