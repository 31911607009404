export const GET_CHAT = "GET_CHAT";
export const SET_CHAT = "SET_CHAT";
export const GET_LOGO = "GET_LOGO";
export const SET_LOGO = "SET_LOGO";
export const GET_AYNC_MESS = "GET_AYNC_MESS";
export const SET_AYNC_MESS = "SET_AYNC_MESS";
export const GET_LOGIN = "GET_LOGIN";
export const SET_LOGIN = "SET_LOGIN";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const GET_CHAT_HI = "GET_CHAT_HI";
export const SET_CHAT_HI = "SET_CHAT_HI";
export const GET_LOADING = "GET_LOADING";
export const SET_LOADING = "SET_LOADING";
