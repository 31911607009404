const LS_KEY = {
  chat: "chat",
  auth: "auth",
  chatToken: "chatToken",
  contractkey: "contractkey",
};
const set = {
  chat: (data) => {
    localStorage.setItem(LS_KEY.chat, JSON.stringify(data));
  },
  authToken: (data) => {
    localStorage.setItem(LS_KEY.auth, data);
  },
  chatToken: (data) => {
    localStorage.setItem(LS_KEY.chatToken, data);
  },
  contractKey: (data) => {
    localStorage.setItem(LS_KEY.contractkey, data);
  },
};
const fetch = {
  chat: () => {
    const data = localStorage.getItem(LS_KEY.chat);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  auth: () => {
    const data = localStorage.getItem(LS_KEY.auth);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  chatToken: () => {
    const data = localStorage.getItem(LS_KEY.chatToken);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  contractKey: () => {
    const data = localStorage.getItem(LS_KEY.contractkey);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
};
const destory = {
  auth: () => {
    localStorage.removeItem(LS_KEY.auth);
  },
  contractKey: () => {
    localStorage.removeItem(LS_KEY.contractkey);
  },
};
export const storage = {
  set,
  fetch,
  destory,
};
