import { GET_AYNC_MESS, GET_CHAT, GET_LOADING, GET_LOGO } from "../ConstAction";

export const sendmessage = (data) => {
  return {
    type: GET_CHAT,
    data: data,
    loading: true
  };
};
export const getLogo = (data) => {
  return {
    type: GET_LOGO,
    data: data,
  };
};
export const sendasyncmessage = (data) => {
  return {
    type: GET_AYNC_MESS,
    data: data,
  };
};
export const getLoadingStatus = (data) => {
  return {
    type: GET_LOADING,
    data: data,
  };
};
