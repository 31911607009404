/* eslint-disable no-unreachable */
import axios from "axios";
import { API_URL } from "./ApiUrl";
import { toast } from "react-toastify";
import { storage } from "./Storage";
export const PostRequest = async (url, data, credential) => {
  try {
    const res = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: credential
    });
    return res; // returning the data part of the response
  } catch (err) {
    if(err.response.status===401&&window.location.pathname === "/login"){
      toast.error("User Login Failed");
    }else{
    toast.error(err.message);
    }
  }
};
export const GetAuthRequest = async (url, data) => {
  try {
    const res = await axios({
      url: url,
      method: "GET",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ` + storage.fetch.auth(),
      },
    });
    return res ? res : res;
  } catch (err) {
  }
};
export const GetRequest = async (url, data) => {

  try {
    const res = await axios({
      url: API_URL + url,
      method: "GET",
      withCredentials: true,
      headers: {
        Accept: "application/json"
      },
    });
    
    
    // return res;
    return res;
  } catch (err) {
    return err.response
  }
};

export const Api = {
  PostRequest,
  GetRequest,
  GetAuthRequest,
};
